import { defineStore } from 'pinia'
import { db } from '@/firebase'
import {
  doc,
  collection,
  getDocs,
  query,
  where,
  addDoc,
  updateDoc,
  increment
} from 'firebase/firestore'
import { chatGptQuery } from '@/openAI'
import { useStoreCounts } from '@/stores'

const destinationsCollectionRef = collection(db, 'destinations')

export const useStoreDestination = defineStore('storeDestination', {
  state: () => {
    return {
      destination: {},
      isCreatingDestination: false,
      isCreated: false,
      isLoading: false
    }
  },
  actions: {
    async fetch(slug) {
      this.isLoading = true
      this.destination = {}
    
      const destinationsDocsSnap = await getDocs(query(destinationsCollectionRef, where('slug', '==', slug)))

      if (destinationsDocsSnap.docs.length > 1) {
        this.isLoading = false
        return new Error('More than one destination found')
      }

      if (destinationsDocsSnap.empty) {
        this.isCreatingDestination = true
        const res = await this.createDestination(slug)

        if (res instanceof Error) {
          this.isLoading = false
          return new Error('Error creating destination')
        }

        this.isCreated = true

        this.destination = res
      } else {
        const destination = destinationsDocsSnap.docs[0]
        this.destination = { id: destination.id, ...destination.data() }
        this.handleOversizedContent(this.destination)
        console.log(this.destination.id)

        updateDoc(doc(db, 'destinations', destination.id), {
          learnCount: increment(1)
        })
      }

      const storeCounts = useStoreCounts()
      storeCounts.incrementUserCount()

      if (this.isCreated) {
        await new Promise(resolve => setTimeout(resolve, 1000))
        this.isCreated = false
        this.isCreatingDestination = false
      }

      this.isLoading = false
    },
    async createDestination(slug) {
      const destination = slug
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')

      const res = await chatGptQuery(destination)

      if (res instanceof Error) {
        this.isCreatingDestination = false
        return res
      }

      try {
        const destinationWithoutComments = res.replace(/\/\*[\s\S]*?\*\/|\/\/.*/g,'')
        const parsedDestination = JSON.parse(destinationWithoutComments)

        this.handleOversizedContent(parsedDestination)

        addDoc(destinationsCollectionRef, parsedDestination)

        const storeCounts = useStoreCounts()
        storeCounts.incrementDestinationsCount()

        return parsedDestination
      } catch (error) {
        this.isCreatingDestination = false
        return new Error(error.message)
      }
    },
    handleOversizedContent(destination) {
      let parser = new DOMParser()
    
      for (let i = 0; i < destination.slides.length; i++) {
        let slide = destination.slides[i]
    
        if (slide.content.length >= 500) {
          let doc = parser.parseFromString(slide.content, 'text/html')
    
          let lists = doc.getElementsByTagName('ul')
    
          for (let j = 0; j < lists.length; j++) {
            let list = lists[j]
    
            if (list.childElementCount > 2) {
              list.removeChild(list.lastElementChild)
            }
          }

          slide.content = doc.body.innerHTML
        }
      }
    }
  }
})
