import { defineStore } from 'pinia'
import { db } from '@/firebase'
import {
  doc,
  getDoc,
  updateDoc,
  increment
} from 'firebase/firestore'

const userCountDocRef = doc(db, 'analytics', 'user-count')
const destinationsCountDocRef = doc(db, 'analytics', 'destinations-count')

export const useStoreCounts = defineStore('storeCounts', {
  state: () => {
    return {
      userCount: 0,
      destinationsCount: 0
    }
  },
  actions: {
    async fetchAllCounts() {
      await this.fetchUserCount()
      await this.fetchDestinationsCount()
    },
    async fetchUserCount() {
      const userCountDocSnap = await getDoc(userCountDocRef)

      if (userCountDocSnap.exists()) {
        const data = userCountDocSnap.data()
        this.userCount = data.count
      }
    },
    async incrementUserCount() {
      await updateDoc(userCountDocRef, {
        count: increment(1)
      })
    },
    async fetchDestinationsCount() {
      const destinationsCountDocSnap = await getDoc(destinationsCountDocRef)

      if (destinationsCountDocSnap.exists()) {
        const data = destinationsCountDocSnap.data()
        this.destinationsCount = data.count
      }
    },
    async incrementDestinationsCount() {
      this.destinationsCount++
      await updateDoc(destinationsCountDocRef, {
        count: increment(1)
      })
    }
  }
})
