export const popularDestinations = [
  'Paris',
  'New York',
  'Rome',
  'Tokyo',
  'Dubai',
  'Sydney',
  'London',
  'Madrid',
  'Berlin',
  'Seoul'
]
