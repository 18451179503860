<template>
  <main
    class="relative w-full flex flex-col min-h-screen items-center bg-dark-blue text-white transition-all"
    :class="{ 'bg-home sm:bg-cover sm:bg-center' : route.path === '/' }"
  >
    <TheHeader class="sticky top-0 z-10" />

    <div class="w-full flex flex-grow justify-center">
      <router-view></router-view>
    </div>

    <TheFooter />
  </main>
</template>

<script setup>
import { onMounted } from 'vue'
import TheHeader from './components/header&footer/TheHeader'
import TheFooter from './components/header&footer/TheFooter'
import { useRoute } from 'vue-router'
import { useStoreCounts } from '@/stores'

// Route.
const route = useRoute()

// Store counts.
const storeCounts = useStoreCounts()

// Fetch user count.
onMounted(() => {
  storeCounts.fetchAllCounts()
})

</script>
