export default [
  {
    "role": "system",
    "content": "You are a helpful assistant for creating information objects about a travel destination for a travel app."
  },
  {
    "role": "user",
    "content": `
    The information about the destination is displayed in cards (slides).
    There should be 10 cards of important
    and interesting information about the destination for a traveler.
    The 'content' should be html-formatted.
    Cards must be minimum 450 characters but maximum 500 characters long and contain 2 paragraphs.
    Include a list with 3 items in 3 of the slides. If there is a list, include only 1 paragraph.
    Separate paragraphs with a <br> tag.
    Include 1-2 emoji(s) in each card if there's a relevant
    use for it, but not in the same place everytime.
    
    DO NOT include any comments in your response. It must be correct; I have to parse it after.
    You must respond only with the object, nothing else.
    
    Here's an example:
    
    {
      learnCount: 1, //must be one
      name: "Sydney", // Name of city
      slides: [
        {
          content: "<p>Sydney, Australia's largest city, is renowned for its scenic beauty, multiculturalism, and iconic landmarks.</p> <br> <p>Enclosed by the Pacific Ocean, Blue Mountains, and numerous national parks, Sydney offers a unique blend of bustling city life and stunning natural retreats.</p> <br> <p>Be it the famous Sydney Opera House, Harbour Bridge, or the pristine Bondi Beach, there's something for everyone in this vibrant city.</p>", // travel information about the destination
          title "Overview" // title of the card
        },
        {
          content: "<p>Sydney is filled with many iconic sites that you shouldn't miss:</p> <br> <ul> <li><strong>Sydney Opera House:</strong> A masterpiece of modern architecture and a UNESCO World Heritage site.</li> <li><strong>Sydney Harbour Bridge:</strong> Known as the 'Coathanger,' it offers an exhilarating BridgeClimb experience.</li> <li><strong>Bondi Beach:</strong> Famous for its golden sands and great surf.</li> <li><strong>Taronga Zoo:</strong> Home to over 4,000 animals and offers stunning views of Sydney Harbour.</li> <li><strong>Royal Botanic Garden:</strong> A tranquil oasis right in the heart of the city.</li> </ul>",
          title: "Must-Visit Places in Sydney"
        },
        // other slides, up to 10
      ],
      slug: "sydney" //slug of the route
    }
    
    Reply with only READ if you understood.
    `
  },
  {
    "role": "assistant",
    "content": "READ"
  }
]
