<template>
  <div class="flex items-center gap-2">

    <div class="flex items-center gap-2">
      <p class="font-bold text-white">
        Share
      </p>

      <button
        class="w-7 h-7 p-1 flex items-center justify-center bg-sky-500 rounded-full overflow-hidden hover:opacity-80 transition-all"
        @click.prevent="shareOnTwitter"
      >
        <i class="fa-brands fa-twitter"></i>
      </button>

      <button
        class="w-7 h-7 p-1 flex items-center justify-center bg-blue-500 rounded-full overflow-hidden hover:opacity-80 transition-all"
        @click.prevent="shareOnFacebook"
      >
        <i class="fa-brands fa-facebook-f"></i>
      </button>

      <button
        class="w-7 h-7 p-1 flex items-center justify-center bg-green-500 rounded-full overflow-hidden hover:opacity-80 transition-all"
        @click.prevent="shareOnWhatsApp"
      >
        <i class="fa-brands fa-whatsapp"></i>
      </button>
    </div>
  </div>
</template>

<script setup>
// Share on Twitter.
const shareOnTwitter = () => {
  let text = 'Learn About Your Travel Destination Fun & Easy\n\n'
  let url = `https://twitter.com/share?url=${encodeURIComponent(document.URL)}&text=${encodeURIComponent(text)}`
  window.open(url)
}

// Share on Facebook.
const shareOnFacebook = () => {
  const url = encodeURIComponent(window.location.href) // change this to homepage when domain known
  window.open('https://www.facebook.com/sharer/sharer.php?u=' + url, '_blank')
}

// Share on WhatsApp.
const shareOnWhatsApp = () => {
  const text = encodeURIComponent('Learn About Your Travel Destination Fun & Easy: ' + window.location.href)
  window.open('https://wa.me/?text=' + text, '_blank')
}

</script>
