import Fuse from 'fuse.js'
import { allDestinations } from '@/destinations'

// Fuse config.
let options = {
  keys: ['title'],
  includeScore: true,
  threshold: 0.4
}

let fuse = new Fuse(allDestinations, options)

// Search.
export const useSearchDestination = (s) => {
  let result = fuse.search(s)

  if(result.length > 0 && result[0].score < 0.4) {
    return result[0].item
  } else {
    return new Error("Couldn't find that destination")
  }
}
