export default [
  {
    name: 'Home',
    path: '/',
    component: require('@/components/home/PageHome').default
  },
  {
    name: 'LearnTopic',
    path: '/learn/:slug',
    component: require('@/components/learn/PageDestination').default,
    props: true
  },
  {
    name: 'NotFound',
    path: '/:pathMatch(.*)*',
    component: require('@/components/other/PageNotFound').default
  }
]
