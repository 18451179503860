import axios from 'axios'
import primingMessages from './priming'

export const chatGptQuery = async (destination) => {

  // Get API key.
  const apiKey = process.env.VUE_APP_OPENAI_API_KEY

  // Model.
  const model = process.env.VUE_APP_OPENAI_MODEL_GPT4
  
  // Query messages.
  const queryMessages = primingMessages

  // Push prompt to query messages.
  const prompt = {
    "role": "user",
    "content": 'Now create an information object for ' + destination
  }
  queryMessages.push(prompt)

  // specs.
  const max_tokens = 1000
  const temperature = 0.5
  
  // Query.
  try {
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model,
        messages: queryMessages,
        max_tokens,
        temperature
      }, {
        headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + apiKey
        }
      }
    )

    return response.data.choices[0].message.content
  } catch (error) {
    return error
  }
}