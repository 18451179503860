<template>
  <div class="w-full xl:px-60 flex flex-col items-center">
    <div
      v-if="storeDestination.isLoading"
      class="w-full h-full px-3 flex flex-col justify-center items-center gap-5"
    >
      <div
        v-if="!storeDestination.isCreatingDestination"
        class="w-[20rem] md:w-[25rem] mb-3 flex overflow-hidden"
      >
        <img
          src="@/assets/brand/logo.svg"
          alt="morsels logo"
          class="w-full h-full"
        >
      </div>

      <div
        v-else
        class="w-full"
      >
        <div
          v-if="!storeDestination.isCreated"
          class="flex flex-col gap-2 items-center"
        >
          <div class="w-[20rem] md:w-[25rem] mb-3 md:mb-5 flex overflow-hidden">
            <img
              src="@/assets/brand/logo.svg"
              alt="morsels logo"
              class="w-full h-full"
            >
          </div>

          <div class="w-11/12 sm:w-1/2 h-5 mb-3 rounded-md overflow-hidden">
            <div id="progressBar" class="h-full bg-dark-green"></div>
          </div>

          <p class="text-center">
            You are the first one here.
          </p>

          <p class="text-center font-bold">
            Hang on while we create the material for you!
          </p>
        </div>

        <div
          v-else
          class="flex flex-col gap-2 items-center"
        >
          <div class="pop-in w-[10rem] md:w-[12rem] mb-1 flex overflow-hidden">
            <img
              src="@/assets/decoration/check.svg"
              alt="morsels logo"
              class="w-full h-full"
            >
          </div>

          <p class="mb-3 ext-center font-bold text-2xl">
            Done!
          </p>
        </div>
      </div>

      <div
        class="w-full sm:w-[25rem] p-2 flex gap-2 items-start bg-gray-600 rounded-md transition-all"
        :class="storeCounts.userCount !== 0 ? 'scale-100' : 'scale-0'"
      >
        <i class="text-yellow-400 fa-solid fa-lightbulb"></i>

        <p class="text-sm">
          <i class="not-italic text-mid-green font-bold">
            {{ storeCounts.userCount.toLocaleString() }}
          </i>

           adventurers have learned about

          <i class="not-italic text-mid-green font-bold">
            {{ storeCounts.destinationsCount.toLocaleString() }}
          </i>

          destinations already
        </p>
      </div>
    </div>

    <div
      v-else-if="destinationCreationErrorMsg"
      class="w-full px-8 mt-5 flex flex-col items-center gap-3 md:gap-5"
    >
      <img
        class=""
        src="@/assets/decoration/error.svg"
        alt="error image"
      >

      <h2 class="text-2xl md:text-3xl font-bold">
        Oh snap!
      </h2>

      <p class="text-center">
        {{ destinationCreationErrorMsg }}
      </p>

      <a
        :href="'/learn/' + props.slug"
        class="text-accent underline-offset-2 hover:underline"
      >
        Try again
      </a>
    </div>

    <div
      v-else-if="storeDestination.destination.slides"
      class="w-full flex flex-col items-center"
    >
      <TheSlider />

      <router-link
        to="/"
        class="mt-3 md:mt-4 font-roboto text-sm text-gray-400 underline hover:opacity-75"
      >
        Choose again
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { onMounted, defineProps, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStoreDestination, useStoreCounts } from '@/stores'
import { allDestinations } from '@/destinations'
import TheSlider from './TheSlider'
import { useHead } from '@vueuse/head'

// Props.
const props = defineProps(['slug'])

// Router.
const router = useRouter()

// Store learn destination.
const storeDestination = useStoreDestination()

// Store counts.
const storeCounts = useStoreCounts()

// Error creating destination- message.
const destinationCreationErrorMsg = ref('')

// Get destination.
onMounted(async() => {
  const destination = props.slug
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')

  if (!allDestinations.includes(destination)) {
    router.push('/not-found')
  }

  if (!storeDestination.destination.id || storeDestination.destination.slug !== props.slug) {
    const res = await storeDestination.fetch(props.slug)

    if (res instanceof Error) {
      if (res.message === 'Error creating destination') {
        destinationCreationErrorMsg.value = 'Something went wrong creating the material.'
      } else {
        router.push('/not-found')
      }
    }
  }

  // Set page title and description.
  useHead({
    title: 'Learn About ' + storeDestination.destination.name + ' | Morsels',
    meta: [
      {
        name: 'description',
        content: 'Discover what ' + storeDestination.destination.name + ' has to offer. Important bits of information, places to visit, local cuisine, things to do, and more.'
      }
    ]
  })
})

</script>

<style scoped>
#progressBar {
  animation: progress 60s linear forwards;
}

@keyframes progress {
  0% {width: 0%;}
  100% {width: 100%;}
}

@keyframes pop-in {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.pop-in {
  animation: pop-in 0.5s ease-out both;
}
</style>
