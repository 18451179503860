<template>
  <div class="w-full mt-3 md:mt-7 lg:mt-10">
    <div class="w-full flex justify-between items-center">
      <button
        class="hidden md:flex p-2 text-gray-500 dark:text-gray-400 hover:opacity-75"
        @click.prevent="prev"
        :class="{ 'invisible' : currentSlide === 0 }"
      >
        <i class="text-6xl xl:text-7xl fa-solid fa-chevron-left"></i>
      </button>

      <div class="w-full overflow-hidden">
        <Carousel
          class=""
          ref="carousel"
          :items-to-show="1"
          :mouse-drag="false"
          :wrap-around="false"
          :touch-drag="true"
          v-model="currentSlide"
        >
          <Slide
            v-for="(slide, index) in [...storeDestination.destination.slides, {finalSlide: true}]"
            :key="index"
          >
            <div class="w-full md:w-[35rem] min-h-[30rem] max-h-[32rem] md:min-h-[27rem] md:max-h-[29rem] px-4 md:px-10 pt-7 sm:pt-10 pb-5 mx-3 md:mx-5 bg-accent text-black rounded-md">
              <div
                v-if="!slide.finalSlide"
                class="w-full flex flex-col"
              >
                <h2 class="mb-4 md:mb-5 text-left font-bold text-2xl md:text-3xl">
                  {{ slide.title }}
                </h2>

                <p
                  class="text-lg v-html-content"
                  v-html="slide.content" 
                >
                </p>
              </div>

              <div
                v-else
                class="w-full pt-5 flex flex-col items-center"
              >
                <div class="mb-8 flex flex-col gap-3 md:gap-5">
                  <h2 class="font-bold text-2xl md:text-3xl">
                    All done 🎉
                  </h2>

                  <p class="">
                    Hope you learned cool and useful things about {{ storeDestination.destination.name }}.
                  </p>
                </div>

                <form class="relative mb-10 flex flex-col gap-3">
                  <label class="font-bold text-left">
                    Have friends headed there? Share with them!
                  </label>

                  <div class="flex">
                    <input
                      class="w-full p-2 text-black rounded-l-lg outline-2 outline-white disabled:bg-gray-100"
                      disabled
                      type="text"
                      v-model="url"
                    >
                
                    <button
                      class="p-2 bg-dark-blue font-bold text-accent rounded-r-lg underline-offset-2 hover:underline"
                      @click.prevent="copyUrl"
                    >
                      Copy
                    </button>
                  </div>

                  <div class="absolute z-10 bottom-0 translate-y-12 right-0">
                    <p
                      v-if="copySuccessMsg"
                      class="w-fit p-2 bg-white rounded-md text-dark-green font-bold"
                    >
                      {{ copySuccessMsg }}
                    </p>

                    <p
                      v-if="copyErrorMsg"
                      class="w-fit p-2 bg-white rounded-md text-red-500"
                    >
                      {{ copyErrorMsg }}
                    </p>
                  </div>
                </form>

                <div class="w-full flex flex-col gap-3">
                  <p>
                    Ready to learn more?
                  </p>

                  <div class="w-full flex flex-wrap items-start gap-2 text-sm">
                    <div
                      v-for="(destination, index) in popularDestinations"
                      :key="index"
                    >
                      <a
                        v-if="destination !== storeDestination.destination.name"
                        class="px-2 py-1 bg-[#35A29F] rounded-full whitespace-nowrap hover:opacity-90"
                        :href="'/learn/' + useSlug(destination)"
                      >
                        {{ destination }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slide>
        </Carousel>
      </div>

      <button
        class="hidden md:flex p-2 text-gray-500 dark:text-gray-400 hover:opacity-75"
        @click.prevent="next"
        :class="{ 'invisible' : currentSlide > storeDestination.destination.slides.length - 1 }"
      >
        <i class="text-6xl xl:text-7xl fa-solid fa-chevron-right"></i>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useStoreDestination } from '@/stores'
import { Carousel, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import confetti from 'canvas-confetti'
import { popularDestinations } from '@/destinations'
import { useSlug } from '@/composables'

// Url.
const url = window.location.href

// Store learn destination.
const storeDestination = useStoreDestination()

// Carousel.
const carousel = ref(null)

// Current slide.
const currentSlide = ref(0)

// Previous slide.
const prev = () => {
  currentSlide.value--
}

// Next slide.
const next = () => {
  currentSlide.value++
}

// Copy url.
const copySuccessMsg = ref('')
const copyErrorMsg = ref('')

const copyUrl = () => {
  navigator.clipboard.writeText(url)
    .then(() => {
      copySuccessMsg.value = 'Copied!'
      setTimeout(() => {
        copySuccessMsg.value = ''
      }, 2000)
    })
    .catch(() => {
      copyErrorMsg.value = 'Error: '
      setTimeout(() => {
        copyErrorMsg.value = ''
      }, 2000)
    })
}

// Launch confetti.
const hasLaunched = ref(false)

const launchConfetti = () => {
  confetti({
    particleCount: 150,
    spread: 80,
    origin: { y: 1 }
  });
}

watch(currentSlide, (newSlide) => {
  if (newSlide > storeDestination.destination.slides.length - 1 && !hasLaunched.value) {
    setTimeout(() => {
      launchConfetti()
    }, 100)
    hasLaunched.value = true
  }
})

</script>
