export const allDestinations = [
  'New York',
  'Los Angeles',
  'San Francisco',
  'Miami',
  'Las Vegas',
  'Paris',
  'London',
  'Rome',
  'Milan',
  'Barcelona',
  'Madrid',
  'Berlin',
  'Amsterdam',
  'Prague',
  'Vienna',
  'Dubai',
  'Istanbul',
  'Cairo',
  'Cape Town',
  'Sydney',
  'Melbourne',
  'Brisbane',
  'Tokyo',
  'Kyoto',
  'Beijing',
  'Shanghai',
  'Bangkok',
  'Phuket',
  'Bali',
  'Marrakesh',
  'Rio de Janeiro',
  'Buenos Aires',
  'Mexico City',
  'Toronto',
  'Vancouver',
  'Montreal',
  'Auckland',
  'Wellington',
  'Queenstown',
  'Seoul',
  'Busan',
  'Hong Kong',
  'Mumbai',
  'Delhi',
  'Singapore',
  'Kuala Lumpur',
  'Jakarta',
  'Hanoi',
  'Ho Chi Minh City',
  'Manila',
  'Santiago',
  'Lima',
  'Havana',
  'Kingston',
  'Brussels',
  'Copenhagen',
  'Stockholm',
  'Helsinki',
  'Oslo',
  'Dublin',
  'Edinburgh',
  'Manchester',
  'Lisbon',
  'Porto',
  'Athens',
  'Thessaloniki',
  'Budapest',
  'Warsaw',
  'Krakow',
  'Zurich',
  'Geneva',
  'Honolulu',
  'Orlando',
  'Boston'
]
