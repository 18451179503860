<template>
  <header
    class="w-full mb-5 transition-all"
    :class="{ 'bg-dark-blue/70 backdrop-blur-md' : hasScrolled }"
  >
    <div class="flex items-center justify-between w-full p-3 lg:py-3 lg:px-5">
      <div class="flex items-center gap-2">
        <!-- Title. -->
        <a
          href="/"
          class="w-24 md:w-32 h-9 flex items-center justify-center overflow-hidden hover:opacity-80 transition-all"
        >
          <img
            src="@/assets/brand/logo.svg"
            alt="morsels logo"
            class="w-full h-full"
          >
        </a>
      </div>

      <!-- Share buttons. -->
      <ShareButtons />

    </div>
  </header>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import ShareButtons from '../utils/ShareButtons'

// Handle scrolling to set a bottom border.
let hasScrolled = ref(false)

const handleScroll = () => {
    hasScrolled.value = window.scrollY > 0
}

onMounted(() => {
    window.addEventListener('scroll', handleScroll)
})

onBeforeUnmount(() => {
    window.removeEventListener('scroll', handleScroll)
})

</script>
