<template>
  <div class="w-full h-full px-3 py-10 flex flex-col items-center">
    <div class="lg:w-[25rem] mb-5 flex overflow-hidden">
      <img
        src="@/assets/decoration/404.svg"
        alt="404"
        class="w-full h-full"
      >
    </div>

    <h1 class="mb-3 text-center font-bold text-2xl md:text-3xl">
      Nothing found here!
    </h1>

    <router-link
      to="/"
      class="text-accent underline-offset-2 hover:underline"
    >
      Head to Home
    </router-link>
  </div>
</template>
