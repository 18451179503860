<template>
  <div
    class="w-full mt-14 md:mt-20 xl:mt-32 py-5 sm:py-3 px-5 md:px-16 text-sm flex flex-col gap-4 sm:gap-0 sm:flex-row items-center justify-between whitespace-nowrap text-gray-300"
    :class="{ 'bg-slate-900' : route.path === '/' }"  
  >
    <div class="w-1/2 flex flex-col sm:flex-row items-center justify-center gap-3 sm:gap-0 sm:justify-start sm:divide-x divide-gray-400">
      <div class="sm:pr-5 flex items-center gap-1">
        <i class="text-gray-500 fa-solid fa-copyright"></i>
        
        <p>
          2023 Morsels App.
        </p>

        <p>
          All rights reserved.
        </p>
      </div>
    </div>

    <div class="w-1/2 flex items-center justify-center sm:justify-end">
      <span class="flex gap-1">
        <p>
          Created with
            <i class="text-red-500 fa-solid fa-heart"></i>
          by
        </p>

        <a
          href="https://twitter.com/ilprdev"
          target="_blank"
          rel="noopener noreferrer"
          class="text-accent hover:underline transition-all"
        >
          @ilprdev
        </a>
      </span>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'

// Route.
const route = useRoute()

</script>
